<template>
    <div class="animated fadeIn">
        <div class="wrapper">
            <div>
                <CRow>
                    <CCol lg="12">
                        <CButton
                            @click="$router.go(-1)"
                            size="sm"
                            color="warning" 
                        > 
                            <font-awesome-icon icon="arrow-left"/> Go back
                        </CButton>
                    </CCol>
                </CRow>
                <br>
                <CRow>
                    <CCol lg="8">
                        <CCard>
                            <CCardHeader>
                                <font-awesome-icon icon="cog"/>  Manage Group
                                
                                <template v-if="group.status == 'Active'">
                                    <CButton 
                                        size="sm"
                                        color="danger" 
                                        class="float-right" 
                                        @click="toggleEdit(), returnInformation()" 
                                        v-show="edit"
                                    >
                                        <font-awesome-icon icon="window-close"/> Cancel
                                    </CButton>

                                    <CButton 
                                        v-if="$store.getters['can']('update-group')"
                                        size="sm"
                                        :disabled="!isValid"
                                        color="success" 
                                        class="float-right" 
                                        v-show="edit" 
                                        @click="updateGroup()"
                                    >
                                        <font-awesome-icon icon="save"/> Save
                                    </CButton>

                                    <CButton 
                                        v-if="$store.getters['can']('edit-group')"
                                        size="sm"
                                        color="info" 
                                        class="float-right" 
                                        @click="toggleEdit" v-show="!edit"
                                    >
                                        <font-awesome-icon icon="edit"/> Edit
                                    </CButton>
                                </template>
                                
                                <template v-if="group.status == 'Archived'">
                                    <CButton 
                                        v-if="$store.getters['can']('restore-group')"
                                        size="sm"
                                        color="success" 
                                        class="float-right" 
                                        @click="restore()"
                                    >
                                    <font-awesome-icon icon="trash-restore"/> Restore
                                    </CButton>
                                </template>
                                
                            </CCardHeader>
                            <CCardBody>
                                <CRow>
                                    <CCol lg="8">
                                        <CInput 
                                            :disabled="disable == 1 ? true : false"
                                            :lazy="false"
                                            :value.sync="$v.group.icon.$model"
                                            :isValid="checkIfValid('icon')"
                                            label="Group Icon" 
                                            type="text" 
                                            autocomplete="off" 
                                            readonly 
                                            v-model="group.icon"
                                        >
                                            <template #prepend-content>
                                                <CIcon :name="group.icon"/>
                                            </template>
                                            
                                            <template #append>
                                                <CButton 
                                                    :disabled="disable == 1 ? true : false"
                                                    color="primary"  
                                                    @click="$refs.iconModal.modal = true, emitIconModal()
                                                ">
                                                <font-awesome-icon icon="search"/>
                                                </CButton>
                                            </template>
                                        </CInput>
                                    </CCol>
                                        <CCol lg="4">
                                            <label>Visible in all department?</label>
                                            <CSwitch 
                                                :disabled="disable == 1 ? true : false"
                                                color="primary" 
                                                variant="opposite" 
                                                shape="pill" 
                                                :checked.sync="group.general"
                                                v-bind="labelTxt" />
                                        </CCol>
                                    <CCol lg="6">
                                        <CInput 
                                            :disabled="disable == 1 ? true : false"
                                            :lazy="false"
                                            :value.sync="$v.group.name.$model"
                                            :isValid="checkIfValid('name')"
                                            label="Name" 
                                            type="text" 
                                            placeholder="Warehouse" 
                                            autocomplete="off"  
                                            v-model="group.name"
                                            invalidFeedback="Must be more than three(3) characters."
                                            v-tocapitalize
                                            v-nospecialcharacter
                                        />
                                    </CCol>
                                    <CCol lg="6">
                                        <CInput 
                                            :disabled="disable == 1 ? true : false"
                                            :lazy="false"
                                            :value.sync="$v.group.path.$model"
                                            :isValid="checkIfValid('path')"
                                            label="Path" 
                                            type="text" 
                                            placeholder="warehouse" 
                                            autocomplete="off"  
                                            v-model="group.path"
                                            readonly
                                        >
                                        
                                            <template #prepend-content>
                                                /
                                            </template>
                                        </CInput>
                                    </CCol>
                                    <CCol lg="12">
                                        <CTextarea
                                            :disabled="disable == 1 ? true : false"
                                            label="Description"
                                            placeholder="Content..."
                                            autocomplete="off"  
                                            v-model="group.description"
                                            rows="3"
                                            v-tocapitalize
                                        />
                                    </CCol>
                                    
                                    <CCol lg="12">
                                        <label>Status</label>: <CBadge :color="getColorStatus(group.status)">{{group.status}}</CBadge>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCardBody>
                                        <CRow v-show="edit">
                                            <CCol lg="12">
                                                <CCard borderColor="danger" v-if="$store.getters['can']('archive-group')">
                                                    <CCardHeader>
                                                        <font-awesome-icon 
                                                            icon="exclamation" 
                                                            :style="{ color: 'red' }"
                                                        /> 
                                                        Danger Zone
                                                    </CCardHeader>
                                                    <CCardBody>
                                                        <CRow>
                                                            <CCol lg="8">
                                                                <h6>Archive this Group ?</h6>
                                                            </CCol>
                                                            <CCol lg="12">
                                                                <label> Once archived, you cannot use this group anymore. Please be certain.</label>
                                                            </CCol>
                                                        </CRow>
                                                        <br>
                                                        <CRow>
                                                            <CCol lg="3">
                                                                <CButton shape="pill" color="warning" @click="archive()" > 
                                                                    <font-awesome-icon icon="minus-square" /> Archived 
                                                                </CButton>
                                                            </CCol>
                                                        </CRow>
                                                    </CCardBody>
                                                </CCard>
                                            </CCol>
                                        </CRow>
                                    </CCardBody>
                                </CRow>
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
                <IconsModal ref="iconModal"/>
            </div>
        </div>
    </div>
</template>
<script>
import IconsModal from '../../modals/IconsModal'
import { required,minLength } from "vuelidate/lib/validators"
export default {
    name: 'GroupEdit',
    components: {
        IconsModal,
    },
    data() {
        return {    
            labelTxt: {
                labelOn: 'Yes',
                labelOff: 'No'
            },
            disable: 1,
            edit: false,
            group: {
                id: this.$route.params.id,
                icon: 'cil-folder',
                name: null,
                path: null,
                description: null,
                status: null,
                general: null,
            },
            group_backup: {
                id: this.$route.params.id,
                icon: 'cil-folder',
                name: null,
                path: null,
                description: null,
                status: null,
                general: null,
            },
        }
    },
    computed: {
        isValid () { return !this.$v.group.$invalid },
        isDirty () { return this.$v.group.$anyDirty },
        modules_fields_lists: function () {
            let custom_fields= [
                {key: 'icon', sorter: false},
                {key: 'name'},
            ]
            if(!this.disable) {
                custom_fields.push({ key: 'action',  sorter: false})
            }
            return custom_fields;
        }
    },
    validations: {
        group: {
            icon: { required, },
            name: { required, minLength: minLength(3)  },
            path: { required, },
        }
    },
    mounted() {
        this.$watch(
            "$refs.iconModal.selected",
            (new_value, old_value) => (this.group.icon = new_value)
        )
    },
    created() {
        this.$Progress.start()
        this.getGroup();
        this.$Progress.finish()
    },
    methods: {
        validate: function () {
            this.$v.$touch()
        },
        checkIfValid: function (fieldName) {
            const field = this.$v.group[fieldName]
            if (!field.$dirty) {
                return null
            } 
            return !(field.$invalid || field.$model === '')
        },
        toggleEdit: function() {
            this.edit = !this.edit
            this.disable = (this.disable + 1) % 2
            if(!this.disable) { 
                this.validate();
            }
            else {
                this.$v.$reset()
            }
        },
        returnInformation: function () {
            // ! DEEP CLONE //
            this.group = Vue.util.extend({}, this.group_backup)
        },
        getGroup: function() {
            axios.get('/groups/' + this.group.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.assignData(response.data)
                }
            })
        },
        assignData: function(response){
            this.group = response.data;
            this.group.path = response.data.path.substring(1);
            this.group.general = response.data.general == '1'? true : false;
            this.group.status = response.data.deleted_at ? 'Archived' : 'Active';

            
            this.group_backup = Vue.util.extend({}, response.data);
        },
        validateGroup:function () {
            if( !this.group.path || !this.group.name) {
                return false;
            } 
            return true;
        },
        emitIconModal: function() {
            this.$emit('show_icons_modal');
        },
        archive: function() {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: `You are trying to archive the ${this.group.name}`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                }).then((result) => {
                if(result.value){
                    this.remove()
                }else{
                    this.$swal('Cancelled!','','error');
                }
            })

        },
        remove: function () {
            this.$Progress.start()
            axios.post('/groups/archive/' + this.group.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        timer: 3000,
                        icon: 'success',
                        title: `${this.group.name} successfully archived.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    }).then( () => {
                        this.group.status = 'Archived'
                        this.toggleEdit()
                        this.$Progress.finish()
                    }) 
                }
            })
        },
        restore: function () {
            this.$Progress.start()
            axios.post('/groups/restore/' + this.group.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        timer: 3000,
                        icon: 'success',
                        title: `${this.group.name} restored successfully.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    }).then( () => {
                        this.group = Vue.util.extend({}, this.group_backup)
                        this.group.modules = Vue.util.extend([], this.group_backup.modules)
                        this.group.status = 'Active'
                        this.$Progress.finish()
                    }) 
                }
            })
        },
        updateGroup: function () {
            if(JSON.stringify(this.group_backup) == JSON.stringify(this.group)) {
               this.$swal({
                    icon: "info",
                    title: "Opps!",
                    text: "No changes has been made.",
                })
                return;
            }
            if(this.validateGroup()) {
                this.$swal({
                    icon: "warning",
                    title: "Are you sure?",
                    text: "You are trying to update the group.",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    showLoaderOnConfirm: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    preConfirm: () => { 
                        this.$Progress.start()
                        let data= {
                            icon: this.group.icon,
                            name: this.group.name,
                            path: `/${this.group.path}`,
                            general: this.group.general,
                            description: this.group.description,
                        }
                        return axios.post('/groups/update/' + this.group.id, data, {validateStatus: () => true})
                        .then(response => {
                            if( response.status == 200 ) {
                                this.$swal({
                                    icon: "success",
                                    title: "Success!",
                                    text: `${this.group.name} has been updated.`,
                                }).then(() => {
                                    this.assignData(response.data)
                                    this.toggleEdit()
                                    this.$Progress.finish()
                                })
                            }
                        })
                    }
                }).then((result) => {
                    if(!result.value){
                        this.$swal('Cancelled!','','error');
                    }
                })
                return;
            }
            this.$swal({
                toast: true,
                position: 'top-right',
                showConfirmButton: false,
                timer: 3000,
                icon: 'error',
                title: 'Please fill up required fields.',
                showConfirmButton: false,
                timerProgressBar: true,
            }) 
            return;
        },
        update: function () {
            this.$Progress.start()
            let data= {
                icon: this.group.icon,
                name: this.group.name,
                path: `/${this.group.path}`,
                general: this.group.general,
                description: this.group.description,
            }
            axios.post('/groups/update/' + this.group.id, data, {validateStatus: () => true})
            .then(response => {
                if( response.status == 200 ) {
                    this.$swal({
                        icon: "success",
                        title: "Success!",
                        text: `${this.group.name} has been updated.`,
                    }).then(() => {
                        this.assignData(response.data)
                        this.toggleEdit()
                        this.$Progress.finish()
                    })
                }
            })

        }
    },
    watch:  {
        'group.name': function (value) {
            this.group.path = value.replace(/\s+/g, '-').toLowerCase();
        }
    }
}
</script>
